<!-- 库存审核 -->
<template>
  <div class="list">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <back style="padding: 0"></back>
        <div>
          <!-- <el-select v-model="value" clearable placeholder="是否新品" @change="onSearch">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select> -->
          <el-select v-model="status" clearable placeholder="审核状态" @change="onSearch">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-input v-model="barCode" placeholder="搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-input v-model="productName" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-button type="primary" @click="handleExamine" :disabled="multipleSelection.length == 0">批量审核</el-button>
        </div>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" ref="multipleTable" @selection-change="handleSelectionChange" :row-key="row => row.id">
          <el-table-column type="selection" width="55" :selectable="row => row.chooseStatus == 1" :reserve-selection="true"></el-table-column>
          <el-table-column prop="number" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="serialId" label="商品编号" show-overflow-tooltip width="110px"></el-table-column> -->
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip width="110px"></el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip width="120px"></el-table-column>
          <el-table-column prop="specs" label="规格" show-overflow-tooltip></el-table-column>
          <el-table-column prop="categoryName" label="商品类目" show-overflow-tooltip></el-table-column>
          <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip></el-table-column>
          <el-table-column prop="factoryName" label="厂家" show-overflow-tooltip width="150px"></el-table-column>
          <el-table-column prop="brand" label="品牌" show-overflow-tooltip></el-table-column>
          <el-table-column prop="validityPeriod" label="保质期（天）" show-overflow-tooltip width="110px"></el-table-column>
          <!-- <el-table-column prop="costPrice" label="供货价（元）" show-overflow-tooltip width="110px"></el-table-column> -->
          <el-table-column prop="suggestPrice" label="建议零售价（元）" show-overflow-tooltip width="150px"></el-table-column>
          <el-table-column prop="inventoryCount" label="初始库存数" show-overflow-tooltip width="150px"></el-table-column>
          <!-- <el-table-column prop="isNew" label="是否新品" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.isNew == 0 ? "否" : "是" }}</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="auditStatus" label="审核进度" show-overflow-tooltip width="150px">
            <template slot-scope="scope">
              <span :style="scope.row.auditStatus == 0 ? 'color:red;' : ''">
                {{ scope.row.auditStatus == 0 ? "待审核" : scope.row.auditStatus == 1 ? "审核通过" : "审核不通过" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" fixed="right" width="200px">
            <template slot-scope="scope">
              <!-- <el-button class="btn" type="danger" size="small" plain @click="showDetail(scope.row)" v-if="scope.row.status ==0">审核</el-button>
              <el-button class="btn" type="primary" size="small" plain @click="showDetail(scope.row)" v-if="scope.row.status ==1">查看</el-button> -->
              <el-button class="btn" type="danger" size="small" plain @click="onExamine(scope.row)" v-if="scope.row.auditStatus == 0 && scope.row.isNew == 0">审核</el-button>
              <el-button class="btn" type="primary" size="small" plain @click="showDetail(scope.row)" v-if="scope.row.auditStatus == 2">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    <!-- 批量 -->
    <el-dialog title="批量审核" :visible.sync="dialogAll" :before-close="onCancel">
      <el-form :model="ruleForm" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item label="请选择">
          <el-radio v-model="ruleForm.auditStatus" label="1">审核通过</el-radio>
          <el-radio v-model="ruleForm.auditStatus" label="2">审核不通过</el-radio>
        </el-form-item>
        <el-form-item label="审核不通过原因" v-show="ruleForm.auditStatus == 2">
          <el-input type="textarea" placeholder="请输入内容" v-model="ruleForm.auditMark" maxlength="200" show-word-limit :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitFormAll('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="审核" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item label="请选择">
          <el-radio v-model="ruleForm.auditStatus" label="1">审核通过</el-radio>
          <el-radio v-model="ruleForm.auditStatus" label="2">审核不通过</el-radio>
        </el-form-item>
        <el-form-item label="审核不通过原因" v-if="ruleForm.auditStatus == 2">
          <el-input type="textarea" placeholder="请输入内容" v-model="ruleForm.auditMark" maxlength="200" show-word-limit :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="审核" :visible.sync="showVisible" :before-close="onCancel">
      <el-form :model="ruleForm" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item label="审核不通过原因">
          <el-input disabled="true" type="textarea" placeholder="请输入内容" v-model="ruleForm.auditMark" maxlength="200" show-word-limit :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="onCancel">取 消</el-button>
        <!-- <el-button @click="submitForm('ruleForm')">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      showVisible: false,
      barCode: "",
      productName: "", //
      datetime: [],
      ruleForm: {
        auditStatus: "1",
        auditMark: null,
      },
      tableData: [
        {
          id: 1,
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          show: false,
        },
        {
          id: 2,
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          show: true,
        },
        {
          id: 3,
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          show: false,
        },
        {
          id: 4,
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          show: true,
        },
        {
          id: 5,
          date: "2016-05-08",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 6,
          date: "2016-05-06",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 7,
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      multipleSelection: [], //选中的行
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      options: [
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "1",
          label: "审核通过",
        },
        {
          value: "2",
          label: "审核不通过",
        },
      ],
      options1: [
        {
          value: "0",
          label: "否",
        },
        {
          value: "1",
          label: "是",
        },
      ],
      value: "",
      status: "",
      dialogAll: false,
      dialogVisible: false,
    }
  },
  created() {
    this.getSupplierInfoPageList()
  },
  methods: {
    // 查看/
    showDetail(row) {
      // console.log(row, '查看');
      let data = Object.assign({}, row)
      this.ruleForm.auditMark = data.auditMark
      this.showVisible = true
    },
    handleExamine() {
      this.dialogAll = true
    },
    onExamine(row) {
      this.dialogVisible = true
      let data = Object.assign({}, row)
      // this.ruleForm = data
      this.ruleForm.id = data.id
      this.ruleForm.applyId = data.applyId
      this.ruleForm.inventoryCount = data.inventoryCount
      this.ruleForm.productId = data.productId
      this.ruleForm.shopId = data.shopId
      this.ruleForm.supplyPrice = data.costPrice
      this.ruleForm.suggestPrice = data.suggestPrice
    },
    // 单独提交
    submitForm() {
      console.log(this.ruleForm)
      this.$axios.post(this.$api.shopAudit, this.ruleForm).then(res => {
        if (res.data.code == 100) {
          this.$message({
            message: res.data.desc,
            type: "success",
          })
          this.getSupplierInfoPageList()
          this.onCancel()
        }
      })
    },
    // 批量提交
    submitFormAll() {
      // console.log('批量', this.multipleSelection);
      this.$axios
        .post(this.$api.batchAudit, {
          auditMark: this.ruleForm.auditMark,
          auditStatus: this.ruleForm.auditStatus,
          shopInventoryApplyDtoList: this.multipleSelection,
        })
        .then(res => {
          if (res.data.code == 100) {
            this.$message({
              message: res.data.desc,
              type: "success",
            })
            this.getSupplierInfoPageList()
            this.onCancel()
          }
        })
    },
    // 关闭对话框
    onCancel() {
      this.resetForm()
      this.ruleForm.auditMark = ""
      this.dialogVisible = false
      this.dialogAll = false
      this.showVisible = false
      this.ruleForm.auditStatus = "1"
    },
    // 重置表单
    resetForm() {
      this.$refs.ruleForm.resetFields()
      this.$refs.multipleTable.clearSelection()
    },
    // 选中的数据
    handleSelectionChange(val) {
      this.multipleSelection = val
      //把this.multipleSelection数组中的applyId，id,inventoryCount,productId,shopId,supplyPrice字段提取出来再返回到this.multipleSelection数组中
      this.multipleSelection = this.multipleSelection.map(item => {
        return {
          applyId: item.applyId,
          id: item.id,
          inventoryCount: item.inventoryCount,
          productId: item.productId,
          shopId: item.shopId,
          supplyPrice: item.costPrice,
          suggestPrice: item.suggestPrice,
          // supplyPrice: item.supplyPrice
        }
      })
      console.log("选中", this.multipleSelection)
    },
    // 商超列表
    getSupplierInfoPageList() {
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        auditStatus: this.status, //状态
        barCode: this.barCode,
        productName: this.productName,
        isNew: this.value,
        id: this.$route.query.id,
      }
      this.$axios
        .get(this.$api.portalDetail, {
          params,
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
        })
        .catch(() => {})
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getSupplierInfoPageList()
    },

    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getSupplierInfoPageList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getSupplierInfoPageList()
    },
  },
}
</script>

<style scoped lang="scss">
.list {
  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    // height: 44px;
    // margin-bottom: 20px;

    ::v-deep .el-input {
      margin-left: 20px;
      margin-bottom: 20px;
      width: 200px;
    }

    ::v-deep .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-bottom: 20px;
      margin-left: 20px;
    }

    ::v-deep .el-range-input {
      background-color: #f2f2f2;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .el-textarea {
    width: 86%;

    ::v-deep .el-input__count {
      background: transparent !important;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
